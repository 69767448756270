import Config from "./configProvider";

const parseJwt = () => {
    const token = localStorage.getItem('auth');

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(jsonPayload);
}

const authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) => {
        return fetch(new Request(`${Config.API}/auth`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            }))
            .then(response => response.json())
            .then(auth => {
                if (auth.token) { 
                    localStorage.setItem('auth', auth.token);
                    localStorage.setItem('auth_expiry', auth.expiryUtc);
                }
                else
                    throw new Error(auth.detail);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('auth_expiry');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401) {
            localStorage.removeItem('auth');
            localStorage.removeItem('auth_expiry');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        // Check if auth token exists
        if (!localStorage.getItem('auth') || !localStorage.getItem('auth_expiry'))
            return Promise.reject();

        // Check if auth expired
        let authExpiry = Date.parse(localStorage.getItem('auth_expiry'));
        let now = new Date();
        if (authExpiry < now)
            return Promise.reject();

        // Success
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to get their profile
    getIdentity: () => {
        const token = parseJwt();
        return Promise.resolve({ 
            id: token.nameid, 
            fullName: token.unique_name,
            avatar: `./images/avatars/${token.nameid}.png`
        });
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};

export default authProvider;